import Vue from 'vue'
import Router from 'vue-router'
import common from './common' 

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [...common]

})

router.beforeEach((to, from, next) => {
//    路由发生变化改变description和keyword
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router;