export default {
    //去掉html标签只留文本
    removeLab(str) {
        return str.replace(/<[^>]+>|&[^>]+;/g, "");
    },
    //限制文本字数
    limitNum(str, num) {
        // console.log(str, num)
        if(str){
            return str.substr(0, num) + "..."
        }
        
    },
    //获取当前时间
    getTime() {
        let data = new Date();
        let year = data.getFullYear();
        let month = data.getMonth() + 1;
        let day = data.getDate();
        let hours = data.getHours();
        let minutes = data.getMinutes();
        let seconds = data.getSeconds();
        return year + "-" + (month < 10 ? ("0" + month) : month) + "-" + (day < 10 ? ("0" + day) : day) + " " + (hours < 10 ? ("0" + hours) : hours) + ":" + (minutes < 10 ? ("0" + minutes) : minutes) + ":" + (seconds < 10 ? ("0" + seconds) : seconds)
    },
     /**
	 * 搜索关键字过滤 添加高亮
	 *
	 * @param    {String}  keyword   替换的关键字
	 * @param    {String}  data   替换的内容
	 * @author
	 */
    keywordShow(keyword, data) {
			let subStr = new RegExp(keyword, "ig"); //创建正则表达式对象,不区分大小写,全局查找
			let result = data.replace(
				subStr,
				`<span class="color_orange">${keyword}</span>`
			); //把'is'替换为空字符串
			return result;
		},

}