// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import axios from "axios"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import qs from "qs"
import CryptoJS from 'crypto-js'
import JsEncrypt from 'jsencrypt'
import Encrypt from '@/assets/until'
import axpost from '@/assets/http' //axios
import comJs from '@/assets/common'
import VueAMap from 'vue-amap'
import VueWechatTitle from 'vue-wechat-title'
import vueSeamlessScroll from 'vue-seamless-scroll'
import "assets/css/style.css"
import "babel-polyfill"



Vue.config.productionTip = false
Vue.prototype.axios=axios
Vue.prototype.$qs=qs
Vue.prototype.CryptoJS = CryptoJS
Vue.prototype.$jsEncrypt = JsEncrypt
Vue.prototype.Encrypt = Encrypt
Vue.prototype.$axios = axpost
Vue.prototype.comJs = comJs
Vue.use(VueAMap)
Vue.use(VueWechatTitle)
Vue.use(ElementUI);
Vue.use(vueSeamlessScroll)

Vue.filter('formatDate', function (value) {
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? ('0' + MM) : MM;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  let m = date.getMinutes();
  m = m < 10 ? ('0' + m) : m;
  let s = date.getSeconds();
  s = s < 10 ? ('0' + s) : s;
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
})

VueAMap.initAMapApiLoader({
  key: '9097aed094fe96b6110307c3a991a776', // 高德的keye
  // 插件集合
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder','AMap.convertFrom'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4',
  uiVersion: '1.0'
});
//跳转新页面，回到页面顶部
router.afterEach((to, from, next) => { window.scrollTo(0, 0) })
router.afterEach((to, from, next) => { document.querySelector("body").setAttribute("style", "overflow: auto !important;") });
/* eslint-disable no-new */
new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
