<template>
	<div id="app">
		<router-view v-wechat-title="$route.meta.title+title" v-if="isRouterAlive" />
	</div>
</template>

<script>
// import func from 'vue-editor-bridge'
export default {
	name: "App",
	provide() {
		return {
			reload: this.reload,
		};
	},
	data() {
		return {
			title: "",
			isRouterAlive: true,
		};
	},

	methods: {
		reload() {
			this.isRouterAlive = false;
			this.$nextTick(function () {
				this.isRouterAlive = true;
			});
		},
	},
};
</script>

<style>
#app {
	font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}
</style>
