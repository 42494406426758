import CryptoJS from 'crypto-js'
import { JSEncrypt } from 'jsencrypt'
export default {
    /**
     * @param {*需要加密的字符串 注：对象转化为json字符串再加密} word
     * @param {*aes加密需要的key值，这个key值后端同学会告诉你} keyStr
     * 两个方法中的第一个参数word是待加密或者解密的字符串，第二个参数keyStr是aes加密需要用到的16位字符串的key。如果想对一个js对象加密，需要先把该对象转成json字符串。
     */
    encrypt(word, keyStr) { // 对称加密
        var key = CryptoJS.enc.Utf8.parse(keyStr)
        var srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(word))
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }) // 加密模式为ECB，补码方式为PKCS5Padding（也就是PKCS7）
        return encrypted.toString()
    },
    //非对称加密
    encryptRsa(key) {
        let encryptor = new JSEncrypt() // 新建JSEncrypt对象 
        let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC6m8p/XgR+fN1RrhehITYNy2lKpIjwb5qtNPSUzY3/Jv5N9cNw+9ESGAmL5z/ZH7KpYeyqgQ9Sa4koQuk4020n+DATJMXznkw01iZARcBuh8tyzlCwKjMZz9ZOX3zko0uJo6BL7LRCB/m9e3INFXnakGz/9EHgNAfmDo3BMxN+ywIDAQAB' //把之前生成的贴进来，实际开发过程中，可以是后台传过来的
        encryptor.setPublicKey(publicKey) // 设置公钥 
        let rsaPassWord = encryptor.encrypt(key) // 对需要加密的数据进行加密 
        return rsaPassWord //得到加密后的数据
    },
    // 对称解密
    decrypt(word, keyStr) {
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var decrypt = CryptoJS.AES.decrypt(word, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
    },
    //非对称解密方法
    RSAdecrypt(pas) {
        var privateKey = 'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBALFJE543In+nraiSp5LPYpFK/Eikor8bLL5TFJRyXyDbpKJUbHRV+xuYMb0hEVUN8NrUiZOCyflTurS4Qtul1ByOQm+ZjnvDlTe6MH5rssZzjqQz0bQ7QqMJV+klKUmCyNmvMcd4fUULoIPv1+QTXbn+NeeVsyHI2PHMcH9sCjqTAgMBAAECgYALjbaglflClnvjw4L2N1u2GkvstAYDLFMzKBjsP7PhYU8OYTAcOMo/lyCOscv+XgU3TaiVm21sOT+urV92Dw2Onw4lPWZJP18Su/z9PFtP2Ru2tVsxGxveBy8wWNZlCfc8OJId8hhTzj1JIg6w0ix+AeLBOB2dF93lQHot/QM88QJBAN9+ty9t/egW+3WUIKu1V6ZDS2syB6SSPxkt6Qpv/njo75sfQUsmfmeO2DVk6rq1fNLLzIePylpGcV9afO/CQCkCQQDLEd0BM7F8SmG57aFXLv195rhSjLSpkkrwwB22dgyAHj1nyXzBAzoMt56zcmHEtWopRVtRVv8c4DxQHAkgj4xbAkBF6ai6MIkt0gqlVEoIDLk3bHOqO8NCSvFsGf19Y11SS2Owvc06Cfeew4OE6GW5NE6wzknT2jotlT2f23mbssNJAkBuZ4zfrWouqAYP3xlb3ClnvPuzrdo/Y1EpKVo+LFpJgadi+RHBom56IY7AA00SdIt+Wr32MDVNTsCb0VJJCKKHAkEAtKsqXgPBi8gIcAh6NlS6GHK/Z9B955TH+8enats3Q9xLVvqizMaMb+wvHx7v5RnFyqD49NlrrlWn8shzIJnXSA=='
        let jse = new JSEncrypt();
        // 私钥
        jse.setPrivateKey(privateKey)
        return jse.decrypt(pas);
    },
    //生成随机数
    RandomNumber(pasLen) {
        var pasArr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        var password = '';
        var pasArrLen = pasArr.length;
        for (var i = 0; i < pasLen; i++) {
            var x = Math.floor(Math.random() * pasArrLen);
            password += pasArr[x];
        }
        return password;
    },
    //访问接口前对数据加密的公用方法
    /*userParam  字符串拼接*/
    PublicEntryption(userParam) {
        //token token值
        //Page 当前页
        //PageSize 每页默认10条
        //UserType type为1时 参会企业  需要传此参数  传1采购商 2供货商
        let ariginalKey = this.RandomNumber(16); //生成随机得key值
        let EntryptionUserParam = this.encrypt(userParam, ariginalKey); //用随机生成的key值对称机密用户信息
        let encryptRsakey = this.encryptRsa(ariginalKey); //对随机生成的key值进行非对称加密
        let reslutModel = {
            encryptData: EntryptionUserParam,
            key: encryptRsakey
        };
        return encodeURIComponent(JSON.stringify(reslutModel))
    },
    //解密
    PublicDecode(response) {
        let keyParam = response.key; //对解密要用到得key值进行解码
        let dataParam = response.encryptData; //对返回来的数据进行解码
        let key = this.RSAdecrypt(keyParam); //非对称解密 key值
        let data = this.decrypt(dataParam, key); //用得到的key值对返回来数据进行对称解密
        return data
    },
    // 提取富文本中的纯文本
    getSimpleText(richCont) {
        const value = richCont
        if (richCont) {
            // 方法三
            var re1 = new RegExp('<.>', 'g') // 匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
            var msg = value.replace(re1, '') // 执行替换成空字符
                // debugger
            msg = msg.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, '')
            msg = msg.replace(/\s*/g, '') // 去掉空格
            msg = msg.replace(/<[^>]+>/g, '') // 去掉所有的html标记
            msg = msg.replace(/↵/g, '') // 去掉所有的↵符号
            msg = msg.replace(/[\r\n]/g, '') // 去掉回车换行
            msg = msg.replace(/&nbsp;/g, '') // 去掉空格
            msg = msg.replace(/&ldquo;/g, '“') // 左双引号
            msg = msg.replace(/&rdquo;/g, '”') // 右双引号
            msg = msg.replace(/&middot;/g, '·') //
            msg = msg.replace(/&mdash;/g, '—') //
            msg = msg.replace(/&amp;/g, '&') // AND符号
            msg = msg.replace(/&lt;/g, '<') // 小于符号
            msg = msg.replace(/&gt;/g, '>') // 大于符号
            msg = msg.replace(/&iquest;/g, '？') // 倒问号
            msg = msg.replace(/&laquo;/g, '《') // 双左箭头
            msg = msg.replace(/&raquo;/g, '》') // 双右箭头
            msg = msg.replace(/&lsquo;/g, "'") // 左单引号
            msg = msg.replace(/&rsquo;/g, "'") // 右单引号
            msg = msg.replace(/&hellip;/g, '...')
            msg = msg.replace(/<\/?.+?>/g, '').replace(/ /g, '')
            return msg
        } else {
            return null
        }
    },
   
}