module.exports =  [
    {
      path: '/',
      name: 'index',
      component: resolve => require(['@/components/index.vue'], resolve),
      meta: {
        title: "铝矾土 刚玉 展会 孝义 会议_第五届铝矾土交易洽谈会 第二届刚玉质耐火原料交易洽谈会首页",
        content:{
          keywords: "铝矾土、刚玉、展会、孝义、会议",
          description: "受市场需求和生矿资源双重影响，全国铝矾土企业开工率创新低，85以上品位铝矾土偏紧状态日趋显现，价格高位运行。刚玉与矾土境遇不同，去年是疫情三年以来刚玉市场最为艰难的一年，可谓举步维艰。由于轻烧矾土资源紧张，叠加电力资源紧缺影响，棕刚玉开工受限;去年工业氧化铝则延续全年下滑趋势，仅在12月份出现小幅上涨，但对白刚玉、致密刚玉、板状刚玉等刚玉类产品支撑有限。"
        }
      }
    },
    {
      path: '/enterprise',
      name: 'enterprise',
      component: resolve => require(['@/components/enterprise.vue'], resolve),
      meta: {
        title: "耐火材料 山西 孝义 河南 三门峡 郑州第五届铝矾土交易洽谈会 第二届刚玉质耐火原料交易洽谈会线上云展",
        content:{
          keywords: "公司、莫来石、氧化铝、尖晶石",
          description: "刚玉质耐火原料，高铝质耐火原料，智能装备企业以及对莫来石、氧化铝、尖晶石等产品的线上云展"
        }
      }
    },
    {
      path: '/introduce',
      name: 'exintroduce',
      component: resolve => require(['@/components/introduce.vue'], resolve),
      meta: {
        title: "中国耐火、山西省耐火、河北省耐火、银耐联第五届铝矾土交易洽谈会 第二届刚玉质耐火原料交易洽谈会大会介绍",
        content:{
          keywords: "耐火材料、山西、孝义、河南、唐山",
          description: "为了应对耐材市场局势，加强矾土和刚玉技术应用，给下游企业提供更多的市场参考和优势资源，促进供求交易;根据国家当前疫情防控形势以及山西孝义政府的防控规定，经中国耐火材料行业协会、山西省耐火材料工业协会、河北省耐火材料行业协会、唐山银耐联电子商务有限公司商议，第五届铝矾土交易洽谈会和第二届刚玉质耐火原料交易洽谈会合并召开。"
        }
      }
    },
     {
      path: '/dynamic',
      name: 'dynamic',
      component: resolve => require(['@/components/dynamic.vue'], resolve),
      meta: {
        title: "铝矾土 刚玉 耐火 孝义 三门峡_第五届铝矾土交易洽谈会 第二届刚玉质耐火原料交易洽谈会大会动态",
        content:{
          keywords: "动态、铝矾土、刚玉、孝义、展会",
          description: "耐材之窗全程对此次会议会进行独家报道，新鲜展会信息就在大会动态栏目。"
        }
      }
    },
      {
      path: '/specimens',
      name: 'specimens',
      component: resolve => require(['@/components/specimens.vue'], resolve),
      meta: {
        title: "铝矾土 刚玉 耐火 孝义 三门峡_第五届铝矾土交易洽谈会 第二届刚玉质耐火原料交易洽谈会大会集锦",
        content:{
          keywords: "集锦、会议、论坛、铝矾土、刚玉",
          description: "此栏目是第五届铝矾土交易洽谈会、第二届刚玉质耐火原料交易洽谈会以及高铝、刚玉质原料创新应用与高效供应链发展论坛的照片及视频回顾"
        }
      }
    },
     {
      path: '/floorplan',
      name: 'floorplan',
      component: resolve => require(['@/components/floorplan.vue'], resolve),
      meta: {
        title: "铝矾土 刚玉 展会 孝义 三门峡_第五届铝矾土交易洽谈会 第二届刚玉质耐火原料交易洽谈会大会展位图栏目",
        content:{
          keywords: "铝矾土、刚玉、孝义、展会、参展企业、展位",
          description: "第五届铝矾土交易洽谈会、第二届刚玉质耐火原料交易洽谈会的参会参展企业可以从展位图查看，展位分为标展和洽谈桌，展位现场设有展架摆放区域。"
        }
      }
    },
     {
      path: '/mediaFocus',
      name: 'mediaFocus',
      component: resolve => require(['@/components/mediaFocus.vue'], resolve),
      meta: {
        title: "铝矾土 刚玉 展会 孝义 三门峡_第五届铝矾土交易洽谈会 第二届刚玉质耐火原料交易洽谈会大会大会服务",
        content:{
          keywords: "大会服务、路线、铝矾土、刚玉、展会",
          description: "欢迎各位耐火原料客商，希望您能拥有愉快的参展体验，栏目包含参展联系方式、会场交通以及其它参展注意事项。"
        }
      }
    },
    {
      path: '/forum',
      name: 'forum',
      component: resolve => require(['@/components/forum.vue'], resolve),
      meta: {
        title: "同期活动"
      }
    },
    {
      path: '/forumdetails',
      name: 'forumdetails',
      component: resolve => require(['@/components/forumdetails.vue'], resolve),
      meta: {
        title: "同期活动详情"
      }
    },
    {
      path: '/dynamicdetails',
      name: 'dynamicdetails',
      component: resolve => require(['@/components/dynamicdetails.vue'], resolve),
      meta: {
        title: "媒体聚焦详情"
      }
    },
    {
      path: '/information',
      name: 'information',
      component: resolve => require(['@/components/information.vue'], resolve),
      meta: {
        title: "资料下载"
      }
    },
    {
      path: '/associatedMedia',
      name: 'associatedMedia',
      component: resolve => require(['@/components/associatedMedia.vue'], resolve),
      meta: {
        title: "合作媒体"
      }
    },
    {
      path: '/csshi',
      name: 'ceshi',
      component: resolve => require(['@/components/floorplan.vue'], resolve),
      meta: {
        title: "测试"
      }
    },
    {
      path: '/contactus',
      name: 'contactus',
       component: resolve => require(['@/components/contactus.vue'], resolve),
      meta: {
        title: "联系我们"
      }
    },
    {
      path:'*',
      name:'404',
      meta: {
        title: "404"
      },
      component: resolve => require(['@/components/load404.vue'], resolve) 
    }
]