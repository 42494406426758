import Vue from 'vue';
import axios from 'axios';
import qs from 'qs'
import Encrypt from '@/assets/until'

Vue.prototype.$axios = axios;
axios.defaults.headers.post['Content-Type'] = 'lication/json;charset=UTF-8'; // 配置请求头
axios.defaults.timeout = 60000; // 请求超时
//http request 拦截器 传token
axios.interceptors.request.use(config => {
        config.headers.token='123456789'
        return config;
    },
    error => {
        return Promise.reject(err);
    }
);
//response拦截器
axios.interceptors.response.use(response => {
    // 根据需要对返回数据进行处理
    if (response.data.isSuccess < 1) {
        console.log(response.data)
        return response;
    }
    return Promise.resolve(response)
}, error => {
    // 检查状态
    // switch (error.response.status) {
    //     case 402:
    //         // 清除token信息
    //         alert('登录过期，请重新登录')
    //             // 清除token信息
    //         localStorage.setItem('accessToken', '')
    //             // permissionUtil.logout()

    //         return error
    //     case 404:
    //         // 跳转404
    //         router.replace({
    //             path: '/404'
    //         })
    //         break
    // }
    return Promise.reject(error)
})
export default {
    post(url, params) {
        return new Promise((resolve, reject) => {
            let pardata = Encrypt.PublicEntryption(qs.parse(params))
            axios.post(url, pardata)
                .then(res => {
                    // console.log(res,'有啥')
                    if (res.data.reslutModel) {
                        let backdata = [];
                        backdata.push(Encrypt.PublicDecode(res.data.reslutModel));
                        backdata.push(res.data.thePage)
                      if (res.data.map) backdata.push(res.data.map)
                        resolve(backdata);
                    } else {
                        resolve(res);
                    }

                })
                .catch(err => {
                    reject(err.data)
                })
        });
    },
    get(url) {
        return new Promise((resolve, reject) => {
            axios.get(url).then(res => {
                console.log(res)
                let backdata = [];
                backdata.push(Encrypt.PublicDecode(res.data.reslutModel));
                backdata.push(res.data.thePage)
                console.log(backdata,'get请求')
                resolve(backdata);
            }).catch(err => {
                reject(err.data)
            })
        })
    }
}